// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-features-js": () => import("./../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-food-drink-js": () => import("./../../src/pages/food-drink.js" /* webpackChunkName: "component---src-pages-food-drink-js" */),
  "component---src-pages-health-safety-js": () => import("./../../src/pages/health-safety.js" /* webpackChunkName: "component---src-pages-health-safety-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-weddings-js": () => import("./../../src/pages/weddings.js" /* webpackChunkName: "component---src-pages-weddings-js" */)
}

